import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import defaultUserImg from '../../assets/images/user.jpg'
import Select from 'react-select';
import * as helper from '../global/helper';
import { getOnboardingUserAll, postOnboardingData, resendOnboardingMail } from "../api/onboardingAPI";
import swal from 'sweetalert2';
import OnboardingTracker from "./onboardingTracker";
import ic_check_on from '../../assets/images/ic_check_on.png'
import { getAuthState } from "../../assets/scripts/login-util";
import OtpModal from "../global/otpModal";
import { getPrimaryViewUser } from "../api/usersAPI";
import UpdateSubscriptionModal from "../payment/updateSubscriptionModal";
import { getSubscriptionValidityForAdmin } from "../api/paymentAPI";

export const OverflowMenu = (props) => {
    return (
        <div className="dropdown-menu dropdown-menu-right" x-placement="top-end">
            <a className="dropdown-item" onClick={props.onResendClick}>
                <i className="la la-mail-reply-all"></i>
                Resend Mail
            </a>
            <a className="dropdown-item" onClick={props.onDeleteClick}>
                <i className="la la-trash"></i>
                Delete
            </a>
        </div>
    );
};

export const OverflowMenuResend = (props) => {
    return (
      <div className="dropdown-menu dropdown-menu-right" x-placement="top-end">
          <a className="dropdown-item" onClick={props.onEditClick}>
            <i className="la la-edit"></i>
            Resend
          </a>
      </div>
    );
};
 
const Onboarding = (props) => {

    const [rightSideDetails,           setRightSideDetails]           = useState(false);
    const [onboardingUserList,         setOnboardingUserList]         = useState([]);
    const [userDetails,                setUserDetails]                = useState({});
    const [isInit,                     setIsInit]                     = useState(true);
    const [membershipTypeOption,       setMembershipTypeOption]       = useState([{ value: 'Golf Membership', label: 'Golf Membership'}, { value:  'Club Membership', label: 'Club Membership'}]);
    const [categoryOption,             setCategoryOption]             = useState([{ value: 'Individual', label: 'Individual'}, { value:  'Organization', label: 'Organization'} ]);
    const [typeOption,                 setTypeOption]                 = useState([{ value: 'Renew', label: 'Renew'}, { value:  'New', label: 'New'} ]);
    const [membershipTermOption,       setMembershipTermOption]       = useState([{ value: 'Annual', label: 'Annual'}, { value:  '3-Years', label: '3-Years'}, { value:  '5-Years', label: '5-Years'}, { value:  'Associate', label: 'Associate'}, { value:  'Honourary', label: 'Honourary'} ]);
    const [spouseOption,               setSpouseOption]               = useState([{ value: 'Yes', label: 'Yes'}, { value:  'No', label: 'No'} ]);
    const [childOption,                setChildOption]                = useState([{ value: 0, label: 0}, { value:  1, label: 1}, { value:  2, label: 2 }, { value:  3, label: 3 }, { value:  4, label: 4 } , { value:  5, label: 5 }]);
    const [action,                     setAction]                     = useState('a');
    const [userID,                     setUserID]                     = useState(null);
    const [showOtpModal,               setshowOtpModal]               = useState(false);
    const [usersList,                  setUsersList]                  = useState([]);
    const [showSubModal,               setShowSubModal]               = useState(false);
    const [subscriptionDetails,        setSubscriptionDetails]        = useState({});
    const [subscriptionValidity,       setSubscriptionValidity]       = useState([]);
    const [subRefresh,                 setSubRefresh]                 = useState(false);
    const [showButton,                 setShowButton]                 = useState(true);

    useEffect(() => {        
        const { user } = getAuthState();  
        setUserID(user.id);      
        props.title('Onboarding', 'onboarding')
        fetchOnboardingUsers();
    },[]); 

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            fetchPrimaryUsers();
            $('#txtMembershipExpiresOn').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtMembershipExpiresOn').on('change', function (event) {
                updateUserDetails('MembershipExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            }); 

        }else{
            setUserDetails({})
            setSubscriptionValidity([])   
            setSubscriptionDetails({})   
            setAction('a');
        }
    },[rightSideDetails]);

    useEffect(() => {
        if (subscriptionDetails && Object.keys(subscriptionDetails).length !== 0) {
            setSubscriptionValidity((a) => {
                const updatedIndex = a.findIndex(obj => obj.OrderID === subscriptionDetails.OrderID);
                return updatedIndex !== -1
                    ? [...a.slice(0, updatedIndex), subscriptionDetails, ...a.slice(updatedIndex + 1)]
                    : [...a, subscriptionDetails];
            });
            setShowButton(false);
        }
    }, [subscriptionDetails]);
  
    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [onboardingUserList]);
  
    const refetchValidity = () => setSubRefresh((r) => !r);

    const fetchOnboardingUsers = async() => {
        try {
            const users = await getOnboardingUserAll()   
            isInit ? setIsInit(false) : $('#tblOnboardingUser').KTDatatable().destroy(); 
            setOnboardingUserList(users);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    const fetchSubscriptionValidity = async(groupID) =>{
        try { 
            const res = await getSubscriptionValidityForAdmin(groupID);
            setSubscriptionValidity(res.filter(f => f.Status != 'Expired'));

        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
            return;
        }
    };

    const fetchPrimaryUsers = async() => {
        try {
            const users = await getPrimaryViewUser()   
            setUsersList(users);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };
  
    function fillDataTable() {

        const dataTable = $('#tblOnboardingUser').KTDatatable({
            data: {
                saveState: false,
                source: onboardingUserList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.ProfilePhoto && row.ProfilePhoto != '') ? `<img src="${row.ProfilePhoto}" alt="user-profile" height="50" width="50">` : `<img src="${defaultUserImg}" alt="user-profile" height="50" width="50">`}
                                </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} 
                                        </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                    </div>
                               </div>`
                    },
                    width: 220,

                },
                {
                    field: 'MembershipType',
                    title: 'Membership',
                    template: function (row) {
                        return row.MembershipType == 'Golf Membership' ? `<span class="kt-badge kt-badge--success kt-badge--pill padding-lr20 padding-tb10"> ${row.MembershipType.slice(0, 4)} </span>` : `<span class="kt-badge kt-badge--info kt-badge--pill  padding-lr20 padding-tb10">${row.MembershipType.slice(0, 4)}</span>`

                    },
                    width: 100, 
                }, 
                {
                    field: '',
                    title: 'Member',
                    template: function (row) {
                        return row.IsSubmit == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Submitted</span>'
                    },
                    width: 100,
                },
                {
                    field: 'AccountSignatureStatus',
                    title: 'Accounts',
                    template: function (row) {
                        return row?.AccountSignatureStatus  == 'Y' ? '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Approved</span>' : '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>'
                    },
                    width: 100,
                },
                {
                    field: 'MembershipSignatureStatus',
                    title: 'Membership',
                    template: function (row) {
                        return row?.MembershipSignatureStatus == 'Y'? '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Approved</span>' : '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>'
                    },
                    width: 100,
                },
                {
                    field: 'AdminSignatureStatus',
                    title: 'Admin',
                    template: function (row) {
                        return row?.AdminSignatureStatus == 'Y' ? '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Approved</span>' : '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>'
                    },
                    width: 100,
                },
                {
                    field: 'PresidentSignatureStatus',
                    title: 'President',
                    template: function (row) {
                        return row?.PresidentSignatureStatus == 'Y' ? '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Approved</span>' : '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>'
                    },
                    width: 100,
                },
                {
                    field: ' ',
                    title: 'Status',
                    template: function (row) {
                        return row?.AccountSignatureStatus  == 'Y' &&  row?.MembershipSignatureStatus  == 'Y' && row?.AdminSignatureStatus  == 'Y' && row?.PresidentSignatureStatus  == 'Y' ? `<span class=""> <img src="${ic_check_on}" height="24"/></span>` :  `<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>`
                    },
                    width: 80,
                },
               
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblOnboardingUser .kt-datatable__table').addClass('overflow-x-auto');
        
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if(dataTable.dataSet[index]){
                setUserDetails(dataTable.dataSet[index])
                setAction('e');
                setRightSideDetails(true);           
             }
        });
    };
    
    const updateUserDetails = (field, value) => { 
        if(field == "Type"){
            setUserDetails({Type: value});
            setSubscriptionValidity([])   
            setSubscriptionDetails({})   
        } 
        if (field === "UserID") updateExistingUser(value)
        setUserDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateExistingUser = (id) => {
        const selectedUser = usersList.find(user => user.ID === id);
        if (selectedUser) {
            fetchSubscriptionValidity(selectedUser.GroupID); 
            setUserDetails({
                ...userDetails,
                UserID: id,
                Category: selectedUser.Category,
                CompanyName: selectedUser?.CompanyName,
                FirstName: selectedUser.FirstName,
                MiddleName: selectedUser.MiddleName,
                MembershipTerm: selectedUser.MembershipNo.slice(0, 2) == 'AH' ? 'Honourary' : 'Annual',
                AssociateID: selectedUser.AssociateID || 0, 
                UserGroupID: selectedUser.GroupID || 0, 
                LastName: selectedUser.LastName,
                Email: selectedUser.Email,
                Mobile: selectedUser.Mobile,
                MembershipType: selectedUser.MembershipType,
                MembershipExpiresOn: selectedUser.MembershipExpiresOn,
                Spouse: selectedUser.Spouse,
                Child: selectedUser.Child.toString(),
            });
        }
    }

    const addSubcriptionHandler = async(e) => {
        e.preventDefault()
        if(!userDetails.MembershipType){ 
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Please select Member Type',
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            return  
        }
        setSubscriptionDetails({})
        setShowSubModal(true)
    };

    const addChargeHandler = async(e) => { 
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-onbording-form');
        
        form.validate({
            rules: {         
                ddlType: {
                    required: true
                },           
                ddlMember: {
                    required: true
                },          
                ddlMembershipTerm: {
                    required: true
                },          
                txtMembershipExpiresOn: {
                    required: true
                },          
                txtCompanyName: {
                    required: true
                },          
                txtFirstName: {
                    required: true
                },          
                txtLastName: {
                    required: true
                },
                txtEmail: {
                    required: true,
                },         
                txtMobile: {
                    required: true,
                    number: true,
                    minlength: 10,
                    maxlength: 10,
                },         
                ddlMemberType: {
                    required: true
                },          
                ddlCategory: {
                    required: true
                },          
                ddlSpouse: {
                    required: true
                },          
                ddlChild: {
                    required: true
                }          
            },
            messages: {
                ddlType: {
                    required: "Please enter type"
                }, 
                ddlMember: {
                    required: "Please enter member"
                },
                ddlMembershipTerm: {
                    required: "Please enter membership type"
                },
                txtMembershipExpiresOn: {
                    required: "Please select membership expires on"
                },
                txtFirstName: {
                    required: "Please enter first name"
                },
                txtCompanyName: {
                    required: "Please enter company name"
                },
                txtLastName: {
                    required: "Please enter last name"
                },
                txtEmail: {
                    required: "Please enter email"
                },
                txtMobile: {
                    required: "Please enter mobile"
                },
                ddlMemberType: {
                    required: "Please select member type"
                },
                ddlCategory: {
                    required: "Please select category"
                },
                ddlSpouse: {
                    required: "Please select spouse"
                },
                ddlChild: {
                    required: "Please select child"
                }
            }
        });
        
        if (!form.valid()) return;
        
        helper.StartProcessing(btn);

        if(subscriptionValidity.length <= 0){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Please select add subscription',
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            return  
        }
        try {
            const validityDetails = subscriptionValidity.find((f) => !f.SubscriptionValidityID)
         
            await postOnboardingData(userDetails, validityDetails);
            helper.StopProcessing(btn);

            swal.fire({
                icon: 'success',
                titleText: 'Mail sent successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            closeDrawer();
            fetchOnboardingUsers()
            
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        form.clearForm();
        form.validate().resetForm();
    };

    const handleResend = async() => {
        try { 
            const swalResult = await swal.fire({
                title               : 'Resend Onboarding Mail',
                icon                : 'question',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : 'Proceed',
                preConfirm: async () => {
                    try {
                        await resendOnboardingMail(userDetails);
                    } catch (err) {
                        swal.fire({
                            icon                : 'error',
                            titleText           : 'Error!',
                            text                : err.message,
                            buttonsStyling      : false,
                            confirmButtonClass  : 'btn btn-brand',
                        });
                        return;
                    }
                },
            });
            if (!swalResult.value) return;
            swal.fire({
                icon: 'success',
                titleText: 'Mail sent successfully!',
                showConfirmButton: false,
                timer: 3500,
                onOpen: () => swal.hideLoading()    
            });
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    };

    const viewForm = () => {
        window.open(`https://gbgc.golfez.in/membershipForm?id=${userDetails.UrlID}&userID=${userDetails.UrlUserID}`, '_blank', 'noopener');
    };

    function calculateProgress(startDate, endDate) {
        const currentDate = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        // Calculate the difference between start and end dates in milliseconds
        const totalDuration = end - start;
        
        // Calculate the progress duration in milliseconds
        const progressDuration = currentDate - start;
        
        // Calculate the progress percentage
        const progressPercentage = (progressDuration / totalDuration) * 100;
        
        // Ensure progress percentage is within 0-100 range
        return `${Math.min(100, Math.max(0, progressPercentage))}%`;
    };

    const handleSubscriptionClick = (subscription) => {
        if (!!subscription.SubscriptionValidityID) return;
      
        // Ensure `totalValue` is a number, regardless of input type
        const totalValue = typeof subscription?.Total === 'string' 
          ? Number(subscription.Total.replace(/,/g, '')) 
          : Number(subscription.Total);
      
        const gstValue = Number(subscription?.GST);
      
        // Calculate charge and GST amount
        const charge = Math.floor(totalValue / (1 + gstValue / 100) + 0.5);
        const gstAmount = Math.floor((totalValue / (1 + gstValue / 100)) * gstValue / 100);
      
        // Update subscription details
        setSubscriptionDetails({
          ...subscription,
          Charge: charge,
          GSTAmount: gstAmount,
        });
      
        // Show subscription modal
        setShowSubModal(true);
    }; 
    
    return(
        <>
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search User..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => {setRightSideDetails(true)}} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Member</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblOnboardingUser" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="User Details"  onCloseDrawer={() => closeDrawer()}>
                                {action == 'a' ? (
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form className="kt-form" id="add-onbording-form">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Type <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlType" 
                                                                name="ddlType"
                                                                className="multi-custom"
                                                                placeholder="Select Type"
                                                                value={userDetails && typeOption.find((m) => m.value == userDetails.Type )  || ''}
                                                                onChange={(event) => updateUserDetails('Type', event.value)}
                                                                options={typeOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    {userDetails.Type == "Renew" && (
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Member <span className="red">*</span></label>
                                                            <div className="col-3">
                                                                <Select 
                                                                    id="ddlMember" 
                                                                    name="ddlMember"
                                                                    className="multi-custom"
                                                                    placeholder="Select Member"
                                                                    value={(usersList && usersList.length > 0 && userDetails.UserID) && {label: `${usersList.find(m => m.ID == userDetails.UserID).Name} (${usersList.find(m => m.ID == userDetails.UserID).Mobile})`, value: userDetails.UserID} || ''}
                                                                    onChange={(event) => updateUserDetails('UserID', event.value)}
                                                                    options={usersList.map(c => ({
                                                                        label: `${c.Name} (${c.Mobile})`,
                                                                        value: c.ID
                                                                    }))}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Membership Term <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlMembershipTerm" 
                                                                name="ddlMembershipTerm"
                                                                className="multi-custom"
                                                                placeholder="Select Membership Term"
                                                                value={userDetails && membershipTermOption.find((m) => m.value == userDetails.MembershipTerm )  || ''}
                                                                onChange={(event) => updateUserDetails('MembershipTerm', event.value)}
                                                                options={membershipTermOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    {userDetails.MembershipTerm == "Associate" && (
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Tag <span className="red">*</span></label>
                                                            <div className="col-3">
                                                                <Select 
                                                                    id="ddlTag" 
                                                                    name="ddlTag"
                                                                    className="multi-custom"
                                                                    placeholder="Select Member"
                                                                    value={(usersList && usersList.length > 0 && userDetails.AssociateID) && {label: `${usersList.find(m => m.ID == userDetails.AssociateID).Name} (${usersList.find(m => m.ID == userDetails.AssociateID).Mobile})`, value: userDetails.AssociateID} || ''}
                                                                    onChange={(event) => updateUserDetails('AssociateID', event.value)}
                                                                    options={usersList.map(c => ({
                                                                        label: `${c.Name} (${c.Mobile})`,
                                                                        value: c.ID
                                                                    }))}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Member Type <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlMemberType" 
                                                                name="ddlMemberType"
                                                                className="multi-custom"
                                                                placeholder="Select Member Type"
                                                                value={userDetails && membershipTypeOption.find((m) => m.value == userDetails.MembershipType )  || ''}
                                                                onChange={(event) => updateUserDetails('MembershipType', event.value)}
                                                                options={membershipTypeOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Category <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlCategory" 
                                                                name="ddlCategory"
                                                                className="multi-custom"
                                                                placeholder="Select Category"
                                                                value={userDetails && categoryOption.find((m) => m.value == userDetails.Category )  || ''}
                                                                onChange={(event) => updateUserDetails('Category', event.value)}
                                                                options={categoryOption}
                                                                isDisabled ={userDetails.Type != "Renew" ? false : true}
                                                            />
                                                        </div>
                                                    </div>
                                                    {userDetails.Category == "Organization" && (
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Company Name <span className="red">*</span></label>
                                                            <div className="col-xl-4 col-lg-8">
                                                                <input 
                                                                    id="txtCompanyName" 
                                                                    name="txtCompanyName" 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    value={userDetails.CompanyName || ''}
                                                                    onChange={(event) => updateUserDetails('CompanyName', event.target.value)} 
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">First Name <span className="red">*</span></label>
                                                        <div className="col-xl-4 col-lg-8">
                                                            <input 
                                                                id="txtFirstName" 
                                                                name="txtFirstName" 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={userDetails.FirstName || ''}
                                                                onChange={(event) => updateUserDetails('FirstName', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Middle Name </label>
                                                        <div className="col-xl-4 col-lg-8">
                                                            <input 
                                                                id="txtMiddleName" 
                                                                name="txtMiddleName" 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={userDetails.MiddleName || ''}
                                                                onChange={(event) => updateUserDetails('MiddleName',  helper.camelCase(event.target.value))} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Last Name <span className="red">*</span></label>
                                                        <div className="col-xl-4 col-lg-8">
                                                            <input 
                                                                id="txtLastName" 
                                                                name="txtLastName" 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={userDetails.LastName || ''}
                                                                onChange={(event) => updateUserDetails('LastName',  helper.camelCase(event.target.value))} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Email <span className="red">*</span></label>
                                                            <div className="col-xl-4 col-lg-8">
                                                                <input 
                                                                    id="txtEmail" 
                                                                    name="txtEmail" 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    value={userDetails.Email || ''}
                                                                    onChange={(event) => updateUserDetails('Email', event.target.value)} 
                                                                />
                                                            </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Mobile <span className="red">*</span></label>
                                                        <div className="col-xl-3 col-lg-4">
                                                            <input 
                                                                id="txtMobile" 
                                                                name="txtMobile" 
                                                                type="text"
                                                                maxLength="10" 
                                                                className="form-control" 
                                                                value={userDetails.Mobile || ''}
                                                                onChange={(event) => updateUserDetails('Mobile', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div> 
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Spouse <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlSpouse" 
                                                                name="ddlSpouse"
                                                                className="multi-custom"
                                                                placeholder="Select Spouse"
                                                                value={userDetails && spouseOption.find((m) => m.value == userDetails.Spouse )  || ''}
                                                                onChange={(event) => updateUserDetails('Spouse', event.value)}
                                                                options={spouseOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Child <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlChild" 
                                                                name="ddlChild"
                                                                className="multi-custom"
                                                                placeholder="Select Child"
                                                                value={userDetails && childOption.find((m) => m.value == userDetails.Child )  || ''}
                                                                onChange={(event) => updateUserDetails('Child', event.value)}
                                                                options={childOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Membership Expires On <span className="red">*</span></label>
                                                        <div className="col-xl-3 col-lg-4">
                                                            <div className="input-group date">
                                                                <input
                                                                    id="txtMembershipExpiresOn" 
                                                                    name="txtMembershipExpiresOn" 
                                                                    type="text"
                                                                    className="form-control date-input" 
                                                                    readOnly={true}
                                                                    placeholder="Select date"
                                                                    value={userDetails.MembershipExpiresOn ? moment(userDetails.MembershipExpiresOn).format('DD-MM-YYYY') : ''} 
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3"> Subscription <span className="red">*</span></label>
                                                        <div className="col-xl-5 col-lg-7">
                                                            <div>
                                                                {subscriptionValidity.length > 0 ? subscriptionValidity.map((subscription, index) => (
                                                                    <>
                                                                        <div className="kt-portlet" key={index} onClick={() => handleSubscriptionClick(subscription)} style={{boxShadow: '0px 0px 13px 0px rgb(82 63 105 / 25%)'}} onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 0px 20px 0px rgb(82 63 105 / 40%)'} onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 0px 13px 0px rgb(82 63 105 / 25%)'}>
                                                                            <div className="kt-portlet__body">
                                                                                <div className="kt-section kt-section--space-md" style={{marginBottom: '2px'}}>
                                                                                    <div className="kt-widget24 kt-widget24--solid">
                                                                                        <div className="kt-widget24__details">
                                                                                            <div className="kt-widget24__info">
                                                                                                <a href="#" className="kt-widget24__title" title="Click to edit">
                                                                                                    {subscription.Name || subscription.SubscriptionName}
                                                                                                    &nbsp;&nbsp;&nbsp;
                                                                                                    <span className={`kt-badge kt-badge--${subscription.Status == "Active" ? 'success' : 'brand'}  kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                                                        {subscription.Status}
                                                                                                    </span>
                                                                                                </a>
                                                                                                <span className="kt-widget24__desc">
                                                                                                    {subscription.Code}
                                                                                                </span><br/>
                                                                                                <span className="kt-widget24__desc">
                                                                                                    {subscription.Description}
                                                                                                </span>
                                                                                            </div>
                                                                                            <span className={`kt-widget24__stats kt-font-${subscription.Status == "Active" ? 'success' : 'brand'} `}>
                                                                                                ₹{subscription.Total}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="progress progress--sm">
                                                                                            <div className={`progress-bar kt-bg-${subscription.Status == "Active" ? 'success' : 'brand'}`} role="progressbar" style={{ width: calculateProgress(subscription.SubscriptionStartsFrom, subscription.SubscriptionExpiresOn) }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                        </div>
                                                                                        <div className="kt-widget24__action">
                                                                                            <span className="kt-widget24__change">
                                                                                                {moment(subscription.SubscriptionStartsFrom).format('DD-MMM-YYYY')}
                                                                                            </span>
                                                                                            <span className="kt-widget24__number">
                                                                                                {moment(subscription.SubscriptionExpiresOn).format('DD-MMM-YYYY')}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div >
                                                                        {(!!subscription.SubscriptionValidityID && showButton) && (
                                                                            <button
                                                                                onClick={(e) => {addSubcriptionHandler(e), setShowButton(true)}}
                                                                                type="button"
                                                                                className="btn btn-label-brand margin-0 margin-r5"
                                                                                >                 
                                                                            <i className="la la-plus"></i>
                                                                            Add Subscription
                                                                            </button>
                                                                        )}   
                                                                    </div>
                                                                    </>
                                                                )): (
                                                                    <div >
                                                                        <button
                                                                            onClick={addSubcriptionHandler}
                                                                            type="button"
                                                                            className="btn btn-label-brand margin-0 margin-r5"
                                                                            >                 
                                                                        <i className="la la-plus"></i>Add Subscription
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-9 ml-lg-auto">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block"onClick={addChargeHandler}>Send Mail</button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className={`col-12`}> 
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__body">
                                                        <div className="kt-widget kt-widget--user-profile-3">
                                                            <div className="kt-widget__top">
                                                                <img className="kt-widget__pic kt-widget__pic" src={userDetails.ProfilePhoto && userDetails.ProfilePhoto != '' ?  userDetails.ProfilePhoto : defaultUserImg } alt="user-profile"/> 
                                                                 <div className="kt-widget__content">
                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div>
                                                                            <div className="kt-widget__head">
                                                                                <span className="kt-widget__username">
                                                                                    {userDetails.Name}{' '}
                                                                                </span>
                                                                            </div>
                                                                            <div className="kt-widget__subhead padding-t0">
                                                                                <span className="padding-r10">
                                                                                    <i className="flaticon2-new-email"></i>
                                                                                    {userDetails.Email} 
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="kt-widget__action" style={{display: 'flex'}}>
                                                                            <span className="dropdown">
                                                                                <button type="button" className="btn btn-primary" onClick={viewForm}>View Form</button>
                                                                            </span>
                                                                            {userDetails.PresidentSignatureStatus == "N" && (userID == 5 || userID == 8 || userID == 2 || userID == 1 || userID == 9) ? (
                                                                                <span className="dropdown">
                                                                                    <a
                                                                                        className="btn btn-sm btn-clean btn-icon btn-icon-md  margin-l50"
                                                                                        style={{marginTop: '-20px'}}
                                                                                        data-toggle="dropdown"
                                                                                        aria-expanded="true">
                                                                                        <i className="flaticon-more-1"></i>
                                                                                    </a>
                                                                                    <OverflowMenu
                                                                                        onDeleteClick={() => {setshowOtpModal(true)}}
                                                                                        onResendClick={() => handleResend()}
                                                                                    />
                                                                                </span>
                                                                            ) : (
                                                                                <span className="dropdown margin-l50">
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet kt-portlet--mobile">
                                            <div className="kt-portlet__body padding-t0">
                                                <OnboardingTracker
                                                    userDetails={userDetails}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
            {showOtpModal && (
                <OtpModal
                    onDismissModal = {() => setshowOtpModal(false)}
                    emailData = {userDetails.ID}
                    refechData = {() => {fetchOnboardingUsers(), setRightSideDetails(false)}}
                    from = ''
                />
            )}  
            {showSubModal && (
                <UpdateSubscriptionModal
                    show={showSubModal}
                    onDismissModal={setShowSubModal}
                    subscriptionDetails={subscriptionDetails}
                    setSubscriptionDetails={setSubscriptionDetails}
                    subscriptionValidity={subscriptionValidity}
                    userDetails={userDetails}
                    from={'Onboarding'}
                    vIndex={subscriptionValidity.length + 2}
                    refetchValidity={() => refetchValidity()}
                />
            )}
        </>
    )
};

export default Onboarding;