import { useEffect, useState, useLayoutEffect } from "react";
import { getPrimaryViewUser, updateMemberGroup } from "../api/usersAPI";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Select from 'react-select';
import { getSubscriptionValidityForAdmin } from "../api/paymentAPI";
import UpdateSubscriptionModal from "../payment/updateSubscriptionModal";
import subscription_expired from '../../assets/images/subscription_expired.png'
import Loading from "../global/loading";

const EditUser = (props) =>{
    const stepList = ['UpdateUser', 'Subscription'];

    const {onDismissModal, refetchUsers} = props

    const [userDetails,                 setUserDetails]              = useState({});
    const [membershipTypeOption,        setMembershipTypeOption]     = useState([{ value:  'Golf Membership', label: 'Golf Membership'}, { value:  'Club Membership', label: 'Club Membership'}]);
    const [step,                        setStep]                     = useState('UpdateUser');
    const [subscriptionValidity,        setSubscriptionValidity]     = useState([]);
    const [subscriptionDetails,         setSubscriptionDetails]      = useState({});
    const [refresh,                     setRefresh]                  = useState(false);
    const [loading,                     setLoading]                  = useState(false);
    const [membershipTermOption,        setMembershipTermOption]     = useState([{ value: 'Annual', label: 'Annual'}, { value:  '3-Years', label: '3-Years'}, { value:  '5-Years', label: '5-Years'}, { value:  'Associate', label: 'Associate'}, { value:  'Honourary', label: 'Honourary'} ]);
    const [usersList,                   setUsersList]                = useState([]);

    useEffect(() => {
        $('#edit-user-modal').modal({
            backdrop: 'static',
        });
        $('#edit-user-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            onDismissModal(false);   
        });
        $('#txtSubscriptionExpiresOn').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });
        $('#txtSubscriptionExpiresOn').on('change', function (event) {
            updateUsersDetails('SubscriptionExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        });   
        $('#txtMembershipExpiresOn').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });
        $('#txtMembershipExpiresOn').on('change', function (event) {
            updateUsersDetails('MembershipExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }); 
        
    })

    useEffect(() => {
        if (props.userDetails) {
            setUserDetails(props.userDetails)
            fetchPrimaryUsers();
        }
    }, [props.userDetails])

    useEffect(() => {
        fetchSubscriptionValidity();
    }, [refresh])

    useLayoutEffect(() => {
        if(!!step){
            const prevElement = document.querySelectorAll('[data-ktwizard-state = current]');
            
            prevElement.forEach( e => {
                e.removeAttribute('data-ktwizard-state');
            });

            $(`.${step}`).attr('data-ktwizard-state','current');
        }
    }, [step])

    const fetchSubscriptionValidity = async() =>{
        try { 
            setLoading(true);
            const res = await getSubscriptionValidityForAdmin(userDetails.GroupID);
            setSubscriptionValidity(res.filter(f => f.Status != 'Expired'));
            setLoading(false);

        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
            setLoading(false);
            return;
        }
    };

    const addMembersHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-userDetails-form');
        
        form.validate({
            rules: {
                txtMembershipNo: {
                    required: true,
                },
                txtMembershipExpiresOn: {
                    required: true
                }, 
                ddlMembershipTerm: {
                    required: true,
                },
                ddlTag: {
                    required: true,
                },                       
            },
            messages: {
                txtMembershipNo: {
                    required: "Please enter membership no"
                },
                txtMembershipExpiresOn: {
                    required: "Please select membership expires On"
                },
                ddlMembershipTerm: {
                    required: "Please select membership term"
                },
                ddlTag: {
                    required: "Please select member"
                },
            }
        }); 
        if (!form.valid()) return; 
        helper.StartProcessing(btn);

        try { 
            const user = {
                ID:                      userDetails.GroupID,
                MembershipType:          userDetails.MembershipType,
                MembershipTerm:          userDetails.MembershipTerm,
                AssociateID:             userDetails.MembershipTerm != 'Associate' ? 0 : userDetails.AssociateID,
                MembershipNo:            userDetails.MembershipNo,
                MembershipExpiresOn:     userDetails.MembershipExpiresOn,
                PanCardNo:               userDetails.PanCardNo || '',
                AadharCardNo:            userDetails.AadharCardNo || '',
                GstNo:                   userDetails.GstNo || '',
                Address:                 userDetails.Address || '',
                City:                    userDetails.City || '',
                State:                   userDetails.State || '',
                Country:                 userDetails.Country || '',
                Pincode:                 userDetails.Pincode || '',
                Phone:                   userDetails.Phone || '',
                Nominee:                 userDetails.Nominee,
                Remark:                  userDetails.Remark || '',
                Active:                  userDetails.Active     
            };
            await updateMemberGroup(user)
            helper.StopProcessing(btn);
            
            setUsersList([])
            setUserDetails({});
            swal.fire({
                icon: 'success',
                titleText: 'User updated successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            $('#edit-user-modal').modal('hide');
            setTimeout(() => {
                refetchUsers() 
            }, 500);
             
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }


        form.clearForm();
        form.validate().resetForm();

    };

    const fetchPrimaryUsers = async() => {
        try {
            const users = await getPrimaryViewUser()   
            setUsersList(users);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    const updateUsersDetails = (field, value) => { 
        setUserDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        setUserDetails({});
    };

    const refetchValidity = () => setRefresh((r) => !r);

    //StepHandlers ->
    const onStepChange = (currentStep) => {
        onNextHandler(currentStep);
    };

    const onNextHandler = async (steps) => {
        let currentStep = !steps ? step : steps

        fetchSubscriptionValidity();

        if (steps) {
            const cstep = stepList.find(s => s == currentStep)
            if (cstep) setStep(cstep)
        } else {
            const cIndex  = stepList.findIndex( s => s == currentStep);
            if( (cIndex >= 0) & (cIndex != stepList.length) ) setStep( stepList[ cIndex + 1] );
        }
    };

    function calculateProgress(startDate, endDate) {
        const currentDate = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        // Calculate the difference between start and end dates in milliseconds
        const totalDuration = end - start;
        
        // Calculate the progress duration in milliseconds
        const progressDuration = currentDate - start;
        
        // Calculate the progress percentage
        const progressPercentage = (progressDuration / totalDuration) * 100;
        
        // Ensure progress percentage is within 0-100 range
        return `${Math.min(100, Math.max(0, progressPercentage))}%`;
    }
    
 
    return (
        <>
            <div className="modal fade"id="edit-user-modal"tabIndex="-1"role="dialog"aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body pt-4">
                            <div className="kt-grid kt-wizard-v3 kt-wizard-v3--white" id="kt_wizard_v3" >
                                <div className="kt-grid__item">
                                {/* begin: Form Wizard Nav */}
                                    <div className="kt-wizard-v3__nav">
                                        <div className="row kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable padding-lr0">
                                            <div className="col-6">
                                                <div className="kt-wizard-v3__nav-item UpdateUser" data-ktwizard-type="step" id="UpdateUser" >
                                                    <div className="kt-wizard-v3__nav-body">
                                                        <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onStepChange('UpdateUser')} >
                                                            User Details
                                                        </div>
                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="kt-wizard-v3__nav-item Subscription" data-ktwizard-type="step"  id="Subscription" >
                                                    <div className="kt-wizard-v3__nav-body">
                                                        <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onStepChange('Subscription')} >
                                                            Subscription Details
                                                        </div>
                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/* end: Form Wizard Nav */}
                                </div>
                                <div className="kt-grid__item kt-grid__item--fluid ">
                                    <div className="kt-form "> 
                                        <div id="wizard-1" className="kt-wizard-v3__content UpdateUser padding-0 margin-0" data-ktwizard-type="step-content" >
                                            <div className="padding-lr10">
                                                <form className="kt-form" id="add-userDetails-form">
                                                    <div className="row">
                                                        <div className="col-6 form-group" >
                                                            <label>Membership Type</label>
                                                            <Select 
                                                                id="ddlMembershipType" 
                                                                name="ddlMembershipType"
                                                                className="multi-custom"
                                                                value={userDetails && membershipTypeOption.find((m) => m.value == userDetails.MembershipType) || ''}
                                                                onChange={(event) => updateUsersDetails('MembershipType', event.value)}
                                                                options={membershipTypeOption}
                                                            />
                                                        </div> 
                                                        <div className="col-6 form-group">
                                                            <label>Membership Expires On</label>
                                                            <div className="input-group date">
                                                                <input 
                                                                    id="txtMembershipExpiresOn" 
                                                                    name="txtMembershipExpiresOn" 
                                                                    type="text" 
                                                                    className="form-control date-input" 
                                                                    readOnly={true} 
                                                                    value={userDetails.MembershipExpiresOn ? moment(userDetails.MembershipExpiresOn).format('DD-MM-YYYY') : ''} 
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className={`col-${userDetails.MembershipTerm == "Associate" ? "4" : "6"} form-group`} >
                                                            <label>Membership Term</label> 
                                                            <Select 
                                                                id="ddlMembershipTerm" 
                                                                name="ddlMembershipTerm"
                                                                className="multi-custom"
                                                                placeholder="Select Membership Term"
                                                                value={userDetails && membershipTermOption.find((m) => m.value == userDetails.MembershipTerm )  || ''}
                                                                onChange={(event) => updateUsersDetails('MembershipTerm', event.value)}
                                                                options={membershipTermOption}
                                                            />
                                                        </div> 
                                                        {userDetails.MembershipTerm == "Associate" && (
                                                            <div className="col-4 form-group">
                                                                <label>Tag</label>
                                                                <Select 
                                                                    id="ddlTag" 
                                                                    name="ddlTag"
                                                                    className="multi-custom"
                                                                    placeholder="Select Member"
                                                                    value={(usersList && usersList.length > 0 && userDetails.AssociateID) && {label: `${usersList.find(m => m.ID == userDetails.AssociateID).Name} (${usersList.find(m => m.ID == userDetails.AssociateID).Mobile})`, value: userDetails.AssociateID} || ''}
                                                                    onChange={(event) => updateUsersDetails('AssociateID', event.value)}
                                                                    options={usersList.map(c => ({
                                                                        label: `${c.Name} (${c.Mobile})`,
                                                                        value: c.ID
                                                                    }))}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className={`col-${userDetails.MembershipTerm == "Associate" ? "4" : "6"} form-group`} >
                                                            <label>Membership No</label>
                                                            <input 
                                                                style={{ fontWeight: userDetails.MembershipNo ? 'bold' : '' }}
                                                                id="txtMembershipNo" 
                                                                name="txtMembershipNo " 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.MembershipNo  || ''}
                                                                onChange={(event) => updateUsersDetails('MembershipNo', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 form-group ">
                                                            <label>Aadhaar Card No</label>
                                                            <input 
                                                                id="txtAadharCardNo" 
                                                                name="txtAadharCardNo" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.AadharCardNo || ''}
                                                                onChange={(event) => updateUsersDetails('AadharCardNo', event.target.value)} 
                                                            />
                                                        </div>
                                                        <div className="col-4 form-group ">
                                                            <label>Pan Card No</label>
                                                            <input 
                                                                id="txtPanCardNo" 
                                                                name="txtPanCardNo" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.PanCardNo || ''}
                                                                onChange={(event) => updateUsersDetails('PanCardNo', event.target.value)} 
                                                            />
                                                        </div>
                                                        <div className="col-4 form-group ">
                                                            <label>GST No</label>
                                                            <input 
                                                                id="txtGstNo" 
                                                                name="txtGstNo" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.GstNo || ''}
                                                                onChange={(event) => updateUsersDetails('GstNo', event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 form-group ">
                                                            <label>Address</label>
                                                            <textarea 
                                                                id="txtAddress" 
                                                                name="txtAddress" 
                                                                className="form-control" 
                                                                rows="3"
                                                                value={userDetails.Address || ''}
                                                                onChange={(event) => updateUsersDetails('Address', event.target.value)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 form-group ">
                                                            <label>Country</label>
                                                            <input 
                                                                id="txtCountry"
                                                                name="txtCountry " 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.Country  || ''}
                                                                onChange={(event) => updateUsersDetails('Country', event.target.value)} 
                                                            />
                                                        </div>
                                                        <div className="col-4 form-group ">
                                                            <label>State</label>
                                                            <input 
                                                                id="txtState"
                                                                name="txtState " 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.State  || ''}
                                                                onChange={(event) => updateUsersDetails('State', event.target.value)} 
                                                            />
                                                        </div>
                                                        <div className="col-4 form-group ">
                                                            <label>City</label>
                                                            <input 
                                                                id="txtCity"
                                                                name="txtCity " 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.City  || ''}
                                                                onChange={(event) => updateUsersDetails('City', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 form-group ">
                                                            <label>Pincode</label>
                                                            <input 
                                                                id="txtPincode" 
                                                                name="txtPincode" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.Pincode || ''}
                                                                onChange={(event) => updateUsersDetails('Pincode', event.target.value)} 
                                                            />
                                                        </div>
                                                        <div className="col-4 form-group ">
                                                            <label>Phone</label>
                                                            <input 
                                                                id="txtPhone" 
                                                                name="txtPhone" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.Phone || ''}
                                                                onChange={(event) => updateUsersDetails('Phone', event.target.value)} 
                                                            />
                                                        </div>
                                                        <div className="col-4 form-group ">
                                                            <label>Nominee</label>
                                                            <input 
                                                                id="txtNominee" 
                                                                name="txtNominee" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={userDetails.Nominee || ''}
                                                                onChange={(event) => updateUsersDetails('Nominee', event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 form-group ">
                                                            <label>Remark</label>
                                                            <textarea 
                                                                id="txtRemark" 
                                                                name="txtRemark" 
                                                                className="form-control" 
                                                                rows="3"
                                                                value={userDetails.Remark || ''}
                                                                onChange={(event) => updateUsersDetails('Remark', event.target.value)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-6" style={{paddingLeft: '335px'}}>Active</label>
                                                        <div className="col-xl-6 col-lg-6">
                                                            <span className="kt-switch kt-switch--icon">
                                                                <label>
                                                                    <input 
                                                                        id="chkActive" 
                                                                        name="chkActive" 
                                                                        type="checkbox"
                                                                        defaultChecked={props.userDetails.Active == 'N' ? '' : 'checked'}
                                                                        onChange={(event) => updateUsersDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                    />
                                                                    <span></span>
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div> 
                                                </form>
                                            </div>
                                        </div>
                                        <div id="wizard-2" className="kt-wizard-v3__content Subscription" data-ktwizard-type="step-content" >
                                            <div className="padding-lr10">
                                                <div>
                                                {loading ? <Loading/> :(
                                                    subscriptionValidity.length > 0 ? subscriptionValidity.map((subscription, index) => (
                                                        <div className="kt-portlet" key={index} onClick={() => setSubscriptionDetails(subscription)}  style={{boxShadow: '0px 0px 13px 0px rgb(82 63 105 / 25%)'}} onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 0px 20px 0px rgb(82 63 105 / 40%)'} onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 0px 13px 0px rgb(82 63 105 / 25%)'}>
                                                            <div className="kt-portlet__body">
                                                                <div className="kt-section kt-section--space-md" style={{marginBottom: '2px'}}>
                                                                    <div className="kt-widget24 kt-widget24--solid">
                                                                        <div className="kt-widget24__details">
                                                                            <div className="kt-widget24__info">
                                                                                <a href="#" className="kt-widget24__title" title="Click to edit">
                                                                                    {subscription.SubscriptionName}
                                                                                    &nbsp;&nbsp;&nbsp;
                                                                                    <span className={`kt-badge kt-badge--${subscription.Status == "Active" ? 'success' : 'brand'}  kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                                        {subscription.Status}
                                                                                    </span>
                                                                                </a>
                                                                                <span className="kt-widget24__desc">
                                                                                    {subscription.Code}
                                                                                </span><br/>
                                                                                <span className="kt-widget24__desc margin-tb5 padding-r100">
                                                                                    {subscription.Description}
                                                                                </span>
                                                                            </div>
                                                                            <span className={`kt-widget24__stats kt-font-${subscription.Status == "Active" ? 'success' : 'brand'} padding-l100`}>
                                                                                ₹{subscription.Total}
                                                                            </span>
                                                                        </div>
                                                                        <div className="progress progress--sm">
                                                                            <div className={`progress-bar kt-bg-${subscription.Status == "Active" ? 'success' : 'brand'}`} role="progressbar" style={{ width: calculateProgress(subscription.SubscriptionStartsFrom, subscription.SubscriptionExpiresOn) }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                        <div className="kt-widget24__action">
                                                                            <span className="kt-widget24__change">
                                                                                {moment(subscription.SubscriptionStartsFrom).format('DD-MMM-YYYY')}
                                                                            </span>
                                                                            <span className="kt-widget24__number">
                                                                                {moment(subscription.SubscriptionExpiresOn).format('DD-MMM-YYYY')}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    )): (
                                                        <div style={{textAlign: 'center'}} className="padding-t20">
                                                            <img src={subscription_expired} alt="subscription-expired" height="350" width="450" style={{opacity:'0.6', color: 'grey'}}></img>
                                                            <h5>Subscription expired</h5>
                                                        </div>
                                                    )
                                                )}
                                                </div>
                                            </div>
                                            <div style={{textAlign: 'center'}} className="padding-t30">
                                                <button
                                                    onClick={() => setSubscriptionDetails({MemberGroupID: userDetails.GroupID})}
                                                    type="button"
                                                    className="btn btn-label-brand margin-0 margin-r5"
                                                    >                 
                                                   <i className="la la-plus"></i>Add Subscription
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <span className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal" > Close </span>
                            {step != 'Subscription' && (
                                <span id="btnSave" className="btn btn-brand margin-0" onClick={addMembersHandler} > Update User </span>
                            )
                            // :(
                            //     <span id="save-btn" className="btn btn-brand margin-0" onClick={addSubscriptionHandler}> Update Subscription</span>
                            // )}
}
                        </div>
                    </div>
                </div>
            </div>
            {Object.keys(subscriptionDetails).length > 0 && (
                <UpdateSubscriptionModal
                    show={subscriptionDetails}
                    onDismissModal={setSubscriptionDetails}
                    subscriptionDetails={subscriptionDetails}
                    setSubscriptionDetails={setSubscriptionDetails}
                    subscriptionValidity={subscriptionValidity}
                    userDetails={userDetails}
                    from = '#edit-user-modal'
                    refetchValidity={() => refetchValidity()}
                />
            )}
        </>
        
    )
};

  
export default EditUser