import { getAuthState } from "../../assets/scripts/login-util";
import { axiosGet, axiosPost, axiosDelete, axiosPatch } from "./axiosFunctions";

const getMatchPlaytMasterAllUrl         = '/api/admin/matchplay/getMatchPlayMasterAll';
const getMasterPageDataAllUrl           = '/api/admin/matchplay/getMasterPageData';
const getMatchPlayByIDUrl               = (matchplayId) => `/api/admin/matchplay/getMatchPlayByID/${matchplayId}`;
const postMatchPlayUrl                  = '/api/admin/matchplay/postMatchPlay';
const deleteMatchPlayUrl                = (id, pswd) => `/api/admin/matchplay/deleteMatchPlay/${id}/${pswd}`;
const updateMatchPlayPublishUrl         = '/api/admin/matchplay/updateMatchPlayPublish';

export const getMatchPlayMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMatchPlaytMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getMasterPageData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMasterPageDataAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getMatchPlayByID = async(matchplayId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( 
        getMatchPlayByIDUrl(matchplayId), 
        { headers: { auth_token: `bearer ${token}` } } 
    );
    return response;
}

export const getMatchPlayData = async(matchplayId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getMatchPlayDataUrl}/${matchplayId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postMatchPlayData = async (matchPlayMaster) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        postMatchPlayUrl,
      { matchPlayMaster},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteMatchPlayData = async (matchPlayId, password) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteMatchPlayUrl(matchPlayId, password),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};


export const updateMatchPlayPublish= async (matchPlayId, isPublish) => { 

    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        updateMatchPlayPublishUrl, { matchPlayId, isPublish },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};