import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const postOnboardingDataUrl                               = '/api/admin/onboarding/postOnboardingData';
const resendOnboardingMailUrl                             = '/api/admin/onboarding/resendOnboardingMail';
const getOnboardingUserAllUrl                             = '/api/admin/onboarding/getOnboardingUserAll';
const deleteOnboardingUrl                                 = (id, otp) => `/api/admin/onboarding/deleteOnboarding/${id}/${otp}`;

export const getOnboardingUserAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getOnboardingUserAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postOnboardingData = async (memberDetails, validityDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postOnboardingDataUrl,
        { memberDetails, validityDetails},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const resendOnboardingMail = async (memberDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        resendOnboardingMailUrl,
        { memberDetails},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
 
export const deleteOnboarding = async (id, otp) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosDelete(
        deleteOnboardingUrl(id, otp),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
