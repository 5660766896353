import { useState, useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { getMatchPlayMasterAll } from "../api/matchPlayAPI";
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert2';

const MatchPlayList =(props) => {
    
    const [isInit,    setIsInit]      = useState(true);
    const [allMaster, setAllMaster]   = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedMaster, setSelectedMaster] = useState(0);
    const history = useHistory();
    
    //#region Effects

    //Rectify to be refreshed as and when need
    useEffect(()=>{
        fetchMatchPlayMaster();
    },[]);

    //Rectify this with forwardRef, useRef, useImperativeHandle Hooks Approach
    useEffect(()=>{
        if(!!props.refetchData) {
            fetchMatchPlayMaster();
            closeDrawer();
            props?.resetRefetch();
        }
    },[props.refetchData]);

    useEffect(() => {       
        if (openDrawer) {
            Config.Core.OpenDrawer()
            if(props?.comp == 'Master'){
                window.initCkeditor("#txtDescription");
                $('.date-input').datepicker({ autoclose:true, format: 'dd-mm-yyyy', minDate: moment().format('DD/MM/YYYY')});
                $('.dateTime-input').datetimepicker({ autoclose:true, minDate: moment().format('YYYY-MM-DD HH:mm')});

                $('#txtStartDate').on('change', ({target}) => dataHandler('StartDate', moment(target.value, 'DD-MM-YYYY').format('YYYY-MM-DD')));  
                $('#txtEndDate').on('change',   ({target}) => dataHandler('EndDate',   moment(target.value, 'DD-MM-YYYY').format('YYYY-MM-DD')));  
                
                $('#txtOpenDate').on('change',  ({target}) => dataHandler('OpenDate',  moment(target.value, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')));  
                $('#txtCloseDate').on('change', ({target}) => dataHandler('CloseDate', moment(target.value, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')));                                                                
            }

            if(props?.comp == 'draw') $('#panel-right').addClass('width-86p');            
        }else{            
            //Clears SideBar State 
          if(!!props.stateHandler) props?.stateHandler(props?.defaultState);  
          if(props?.comp == 'draw') $('#panel-right').removeClass('width-86p');     
        }    
    }, [openDrawer]);

    useEffect(() => {
        if (props.matchPlayID && allMaster.length > 0) {
            const cMaster = allMaster.find(t => t.ID == props.matchPlayID)
            setSelectedMaster(cMaster);
            props.onSelectHandler(cMaster);
            setOpenDrawer(true)
        }
    }, [props.matchPlayID, allMaster])

    useEffect(() => {       
        if (!isInit) fillDataTable();        
    }, [allMaster]);

    //#endregion

    //#region API Calls Handlers
    
    const fetchMatchPlayMaster = async() => {
        try {
            const masters = await getMatchPlayMasterAll()            
            isInit ? setIsInit(false) : $('#tblMaster').KTDatatable().destroy(); 
            setAllMaster(masters); 
        } catch (error) {
            console.log('first', error)
            if (error != 'Error: KTDatatable not initialized')
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    //#endregion

    //#region Handlers
    function fillDataTable() {
        const dataTable = $('#tblMaster').KTDatatable({
            data: {
                saveState: false, pageSize : 20,
                source : allMaster,
            },
            layout: {
                scroll: true, footer: false, height: $(window).height() - 250,
            },
            sortable  : true, pagination: true,
            search    : { input: $('#generalSearch')},
            columns: [               
                {  field: 'Name',    title: 'Match Play',   width:300,
                   template:(row) => {
                    return (`<div class="kt-user-card-v2">
                            <div class="kt-user-card-v2__pic">
                                ${(row.Icon && row.Icon != '') ? `<img src="${row.Icon}" alt="Trophy_Icon" style="width: 50px; height: 50px;">` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                            </div>
                            <div class="kt-user-card-v2__details text-clip">
                                <span class="kt-user-card-v2__name text-clip"> ${row.Name} 
                                </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Type} | ${row.MembershipType} </span>
                            </div>
                    </div>`);
                   }
                },
                {  field: 'Gender',  title: 'Gender', width:80 },
                {
                   field: 'StartDate', title: 'Start Date', width: 120,
                   template: (row) => moment(row.StartDate).format('DD-MMM-YYYY'),  
                },
                {
                   field: 'EndDate', title: 'End Date', width:120,
                   template: (row) => moment(row.EndDate).format('DD-MMM-YYYY'),                                    
                },
                {
                   field: 'CloseDate', title: 'Close Date', width:120,
                   template: (row) => moment(row.CloseDate).format('DD-MMM-YYYY'),                                    
                },
                {
                    field: 'IsPublished', title: 'Published', width: 80,
                    template:(row)=> {
                        return (row.IsPublished == 'N') ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' 
                        : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>';
                    },
                },                
            ]
        });
    
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            const cMaster = dataTable?.dataSet[index];          
            if (!!cMaster) {
                setSelectedMaster(cMaster);
                props.onSelectHandler(cMaster);
                if (props?.comp == "Leaderboard") {
                    history.push(`/tournament-leaderboard/${cMaster.ID}`);
                } else if (props?.comp == "winner") {
                    history.push(`/tournament-winner/${cMaster.ID}`);
                } else {
                    setOpenDrawer(true);                
                }
            } 
        });    
    }

    const onOpenDrawer =()=> setOpenDrawer(true);

    const closeDrawer =()=>{       
        if(props?.comp == "Master") window.removeCkeditor("txtDescription");
        if(props?.resetData) props?.resetData();
        setSelectedMaster(0)
        if(props?.setMatchPlayID) props?.setMatchPlayID();
        Config.Core.CloseDrawer();
        setTimeout(() => { setOpenDrawer(false) }, 200);

        // Get the current URL and update it
        const currentUrl = window.location.pathname;
        const newUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
        history.push(newUrl);
    }

    const dataHandler = (field, value) => props.stateHandler(d => ({...d, [field]: value}));   
    //#endregion

    return(<>
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    {props?.comp == 'Master' &&(
                                        <button onClick={onOpenDrawer} type="button" className="btn btn-brand btn-icon-sm text-white d-inline">
                                            <i className="la la-plus"></i> New Master 
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblMaster" className="kt-datatable table-striped clickable"></div>
                        {/* Conditional SideBar Child */}
                        {openDrawer && (<>                         
                            <RightSideDetails selectedMaster={selectedMaster} title={props?.title || ""} onCloseDrawer={() => closeDrawer()}> 
                                {props?.children}                                
                            </RightSideDetails> 
                        </>)}
                    </div>
                </div>
            </div>
        </div>  
    </>);
}

export default MatchPlayList;